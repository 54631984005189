import { defineStore } from 'pinia';

// 해상도 체크 공통관리
export const useIsWidth = defineStore('isWidth', () => {
  const resolutionList = ref({
    isMo: false,
    isTablet: false,
    isPc: false,
    isPc1: false,
    isPc2: false,
  });
  const isDone = ref(false);
  const moMedia = {
    key: 'isMo',
    query: '(max-width: 811px)',
  };
  const tabletMedia = {
    key: 'isTablet',
    query: '(min-width: 812px) and (max-width: 1023px)',
  };
  const pcMedia = {
    key: 'isPc',
    query: '(min-width: 1024px)',
  };
  const pc1Media = {
    key: 'isPc1',
    query: '(min-width: 1024px) and (max-width: 1439px)',
  };
  const pc2Media = {
    key: 'isPc2',
    query: '(min-width: 1440px)',
  };

  function changeValue(key, value) {
    resolutionList.value[key] = value;
  }

  function setResolution() {
    [moMedia, tabletMedia, pcMedia, pc1Media, pc2Media].forEach(({ key, query }) => {
      const matchMedia = window.matchMedia(query);
      changeValue(key, matchMedia.matches);
      matchMedia.addEventListener('change', (e) => {
        changeValue(key, e.matches);
      });
    });

    isDone.value = true;
  }

  return { resolutionList, isDone, setResolution };
});

//모바일메뉴 오픈값, pc, 모바일 메뉴값
export const useMenuData = defineStore('menuData', () => {
  const isMobileOpen = ref(false);
  const menuData = ref([
    {
      name: '비즈니스',
      link: '',
      isOutlink: false,
      isSubOpen: false,
      pathData: 'service',
      subTabs: [
        //변경예정
        [
          {
            title: '업무협업',
            list: [{ name: '카카오워크', link: '/service/kakaowork', pathData: 'kakaowork' }],
          },
          {
            title: '음성 AI',
            list: [
              { name: '카카오 i', link: '/service/kakaoi', pathData: 'kakaoi' },
              { name: '카카오 i 오토', link: '/service/kakaoiauto', pathData: 'kakaoiauto' },
              { name: '카카오 홈', link: '/service/kakaohome', pathData: 'kakaohome' },
            ],
          },
        ],
        [
          {
            title: '고객 소통',
            list: [
              {
                name: '카카오 i 커넥트 올웨이즈',
                link: '/service/kakaoiconnectalways',
                pathData: 'kakaoiconnectalways',
              },
              {
                name: '카카오 i 커넥트 센터',
                link: '/service/kakaoiconnectcenter',
                pathData: 'kakaoiconnectcenter',
              },
              {
                name: '카카오 i 커넥트 메시지',
                link: '/service/kakaoiconnectmsg',
                pathData: 'kakaoiconnectmsg',
              },
            ],
          },
          {
            title: 'AI 챗봇',
            list: [
              {
                name: '카카오 i 커넥트 톡',
                link: '/service/kakaoiconnecttalk',
                pathData: 'kakaoiconnecttalk',
              },
              { name: '스마트 민원행정', link: '/service/smartminwon', pathData: 'smartminwon' },
              {
                name: '스마트 건설',
                link: '/service/smartconstruction',
                pathData: 'smartconstruction',
              },
              { name: '챗봇나우', link: '/service/chatbotnow', pathData: 'chatbotnow' },
            ],
          },
        ],
        [
          {
            title: '디지털 전환',
            list: [
              { name: 'DX', link: '/service/dx', pathData: 'dx' },
              { name: 'ITO', link: '/service/ito', pathData: 'ito' },
              { name: 'UI UX 컨설팅', link: '/service/uiux', pathData: 'uiux' },
            ],
          },
          {
            title: 'SAP ERP',
            list: [{ name: 'SAP ERP', link: '/service/sap', pathData: 'sap' }],
          },
        ],
      ],
    },
    {
      name: '고객사례',
      link: '/case',
      isOutlink: false,
      pathData: 'case',
      subTabs: [],
    },
    {
      name: '뉴스',
      link: '/news',
      isOutlink: false,
      pathData: 'news',
      subTabs: [],
    },
    {
      name: '자료실',
      link: '/download',
      isOutlink: false,
      pathData: 'download',
      subTabs: [],
    },
    {
      name: '회사소개',
      link: '/introduce',
      isOutlink: false,
      pathData: 'introduce',
      subTabs: [],
    },
    {
      name: '인재영입',
      link: 'https://dktechin.career.greetinghr.com/',
      isOutlink: true,
      subTabs: [],
    },
  ]);
  function mobileMenuToggle(type) {
    menuData.value.forEach((ele) => {
      ele.isSubOpen = false;
    });
    if (type === 'close') {
      isMobileOpen.value = false;
    } else {
      isMobileOpen.value = !isMobileOpen.value;
    }
  }

  function subMenuToggle(index) {
    menuData.value.forEach((ele, index2) => {
      if (index2 !== index) {
        ele.isSubOpen = false;
      }
    });
    menuData.value[index].isSubOpen = !menuData.value[index].isSubOpen;
  }
  return { menuData, subMenuToggle, mobileMenuToggle, isMobileOpen };
});

// 통합검색 오픈값
export const useSearchLayer = defineStore('isSearchOpen', () => {
  const isSearchOpen = ref(false);
  function toggleSearchLayer(type) {
    if (type === 'close') {
      isSearchOpen.value = false;
    } else {
      isSearchOpen.value = !isSearchOpen.value;
    }
  }
  return { isSearchOpen, toggleSearchLayer };
});

//스크롤 방향 체크
export const useScrollDirection = defineStore('isScrollDirection', () => {
  const isScrollDown = ref(false);
  return { isScrollDown };
});

//공통 딤드처리
export const useDimmedOpen = defineStore('isDimmedOpen', () => {
  const isDimmedOpen = ref(false);
  const toggleDimmed = (status) => {
    isDimmedOpen.value = status === 'open';
  };
  return { isDimmedOpen, toggleDimmed };
});

//다운로드 팝업 상태값
export const useDownloadOpen = defineStore('isDownloadOpen', () => {
  const isDownloadOpen = ref(false);
  const fileLink = ref('');
  const isLink = ref(false);
  const titleName = ref('');
  const serviceName = ref('');
  const openDownloadLayer = (title, link, type, service) => {
    isDownloadOpen.value = true;
    fileLink.value = link;
    titleName.value = title;
    serviceName.value = service;
    isLink.value = type === 'LINK';
  };
  const closeDownloadLayer = () => {
    isDownloadOpen.value = false;
    fileLink.value = '';
    titleName.value = '';
    isLink.value = false;
  };
  return {
    isDownloadOpen,
    fileLink,
    isLink,
    titleName,
    serviceName,
    openDownloadLayer,
    closeDownloadLayer,
  };
});

//도입문의 팝업값
export const useConsultOpen = defineStore('isConsultOpen', () => {
  const isConsultOpen = ref(false);
  const toggleConsultLayer = (status) => {
    status === 'open' ? (isConsultOpen.value = true) : (isConsultOpen.value = false);
  };
  return { isConsultOpen, toggleConsultLayer };
});

//공유하기 팝업값
export const useShareOpen = defineStore('isShareOpen', () => {
  const isShareOpen = ref(false);
  const shareData = ref({
    shareUrl: '',
    shareTitle: '',
    shareImage: '',
  });
  const openShareLayer = ({ shareUrl, shareTitle, shareImage }) => {
    shareData.value.shareUrl = shareUrl;
    shareData.value.shareTitle = shareTitle;
    shareData.value.shareImage = shareImage;
    isShareOpen.value = true;
  };
  const closeShareLayer = () => {
    shareData.value.shareUrl = '';
    shareData.value.shareTitle = '';
    shareData.value.shareImage = '';
    isShareOpen.value = false;
  };
  return { isShareOpen, shareData, openShareLayer, closeShareLayer };
});

//통합검색 인풋값
export const useTotalSearchKeyword = defineStore('totalSearchKeyword', () => {
  const searchWord = ref('');

  const setTotalSearchKeyword = (value) => {
    searchWord.value = value;
  };
  return { searchWord, setTotalSearchKeyword };
});

//뒤로가기여부 관리
export const useBackStore = defineStore('back', () => {
  const isBack = ref(false);
  const setBack = (status) => {
    isBack.value = status;
  };
  return {
    isBack,
    setBack,
  };
});

//도입문의 모일 안내세지 컨트롤
export const useBtnConsultStore = defineStore('consult', () => {
  const isEnd = ref(false);
  const setConsultEnd = () => {
    isEnd.value = true;
  };
  return {
    isEnd,
    setConsultEnd,
  };
});

//cs문의 팝업값
export const useCsInquiryOpen = defineStore('isCsInquiryOpen', () => {
  const isCsInquiryOpen = ref(false);
  const toggleCsInquiryLayer = (status) => {
    isCsInquiryOpen.value = status === 'open';
  };
  return { isCsInquiryOpen, toggleCsInquiryLayer };
});

//확인 레이어 팝업값
export const useToast = defineStore('isToastShow', () => {
  const isToastShow = ref(false);
  const toastText = ref('');
  const showToast = (text) => {
    toastText.value = text;
    isToastShow.value = true;
    setTimeout(() => {
      isToastShow.value = false;
    }, 3000);
  };

  return { isToastShow, showToast, toastText };
});

//desktop체크값
export const useDeviceCheck = defineStore('deviceCheck', () => {
  const isDesktop = ref(true);
  return { isDesktop };
});
